<template>
    <div class="page">
        <div class="header">
            <div class="btns">
                <el-button size="mini" @click="onAdd" icon="el-icon-plus" type="defalut">新建</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh">刷新</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="字典设置" />
        </div>
        <div class="search">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="分类">
                    <el-input v-model="search.type" placeholder="请输入" clearable />
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="search.name" placeholder="请输入" clearable/>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="getData">查询</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                    :header-cell-style="{ backgroundColor: '#FAFAFA' }">
                    <el-table-column type="index" label="序号" align="center"
                        :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column prop="type" label="分类"  show-overflow-tooltip/>
                    <el-table-column prop="name" label="名称"  show-overflow-tooltip/>
                    <el-table-column prop="remark" label="备注" width="" show-overflow-tooltip/>
                    <el-table-column prop="createdAt" label="创建时间" width="220" align="center" :formatter="dateFormatter" show-overflow-tooltip />
                    <el-table-column prop="updatedAt" label="完成时间" width="220" align="center" :formatter="dateFormatter" show-overflow-tooltip/>
                    <el-table-column align="center" width="160" label="操作" fixed="right">
                        <template slot-scope="scope">
                            <el-button size="mini" @click="handleUpdate(scope.row)" >修改
                            </el-button>
                            <el-button  size="mini" @click="handeDel(scope.row)" >删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="pager" v-if="table.total > table.pageSize">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
        <el-dialog title="修改/添加" :visible.sync="Visible" width="30%">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="分类">
                    <el-select
                            style="width: 100%"
                            v-model="form.type"
                            filterable
                            clearable
                            allow-create
                            default-first-option
                            placeholder="">
                        <el-option
                                v-for="item in typeList"
                                :key="item.field"
                                :label="item.name"
                                :value="item.field">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="名称">
                    <el-input v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="form.remark" :rows="2" type="textarea"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="Visible = false">取 消</el-button>
                <el-button type="primary" @click="onSubmit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'setting-dict',
    path: '/main/setting/dict',
    data() {
        return {
            Visible: false,
            loading: false,
            search: { type: '' ,name:''},
            form:{},
            typeList:[
                {field:"project_type",name:'项目类别'},
                {field:"expert_category",name:'专家分类'},
                {field:"expert_department",name:'专家科室'},
                {field:"expert_major",name:'专家专业'},
                {field:"expert_title",name:'专家职称'},
                {field:"expert_education",name:'专家学历'},
            ],
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        dateFormatter(v) {
            return new Date(v.createdAt).format('yyyy-MM-dd')
        },
        onAdd(){
            this.form = {
                type:"",
                name:"",
                remark:""
            }
            this.Visible=true
        },
        handleUpdate(row){
            this.form = row
            this.Visible = true
        },
        handeDel(row){
            this.$http.delete(`/dict/${row.id}/`)
                .then(resp => {
                    if (resp.data.code == 200) {
                        this.$message.success(resp.data.msg)
                        this.getData()
                    }
                }).catch(err => {
                this.$message.error(err.message)
            }).finally(() => {
                this.loading = false
            })
        },
        onSubmit() {
            this.loading = true
            this.$http.post('/dict', this.form)
                .then(resp => {
                    if(resp.data.code==200) {
                        this.Visible = false
                        this.$message.success(resp.data.msg)
                        this.getData()
                    }
                }).catch(err=>{
                this.$message.error(err.message)
            }).finally(()=>{
                this.loading = false
            })
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/dict?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.rows
                this.table.total = resp.data.count
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.header {
    padding-bottom: 10px;

    .btns {
        float: right;
    }
}

.search {
    padding: 10px;
    background-color: #FAFAFA;
    border-top: 1px solid #EBEEF5;
    border-left: 1px solid #EBEEF5;
    border-right: 1px solid #EBEEF5;

    ::v-deep .el-form-item {
        padding: 0 !important;
        margin-bottom: 5px !important;
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.pager {
    padding-top: 10px;
    text-align: center;
}
@media (max-width: 992px) {
    ::v-deep .el-dialog{
        width: 96% !important;
        /*margin: 0 10px;*/
        margin-top: 5vh !important;

    }
}

@media (max-width: 1200px) {
    ::v-deep .el-dialog{
        width: 90% !important;
        /*margin: 0 10px;*/
        /*margin-top: 5vh !important;*/

    }
}
</style>
